<template>
    <div>
        <jy-dialog type="table" title="选择站点" :visible.sync="dialogVisible" custom-class="dialog_table" :width="global.dialogTabelWidth">
            <jy-query ref="checkForm" :model="formInline">
                <jy-query-item label="站点名称:" prop="name" :span="2">
                    <el-input v-model="formInline.name"></el-input>
                </jy-query-item>
                <template slot="rightCol">
                    <el-button type="primary" @click="oncheck">查询</el-button>
                    <el-button type="primary" @click="resetForm('checkForm')">重置</el-button>
                    <!-- <jy-query-item>
                                    <el-button type="primary" @click="onexport">导出</el-button>
                                </jy-query-item> -->
                </template>
            </jy-query>
            <jy-table :data="lists" @row-click="clickStation">
                <jy-table-column type="index" width="60" label="序号">
                </jy-table-column>
                <jy-table-column prop="name" label="站点名称" min-width="120">
                </jy-table-column>
                <jy-table-column prop="alias" label="别名" min-width="120">
                </jy-table-column>
            </jy-table>
            <div class="jy_pagination">
                <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
                </jy-pagination>
            </div>
            <div slot="footer" class="dialog-footer"></div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dialogVisible: false,

            formInline: {
                name: ''
            },

            lists: [{
                name: '',
                alias: ''
            }],

            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10
        }
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('checkForm')
            }
        }
    },
    activated() {
        this.getlist()
    },
    methods: {
        // 初始化
        init() {
            this.dialogVisible = true
        },
        getlist() {
            let url = '/baseinfosite/querySiteInfoByName'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
            this.$http.post(url, option).then(({ detail }) => {
                this.lists = detail.list
                this.total = detail.total
            })
        },

        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        clickStation(row) {
            this.$emit('pushStation', row)
            this.dialogVisible = false
        },
        // 导出
        onexport() {
            console.log('导出')
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        }
    }
}

</script>
<style lang="scss" scoped="details">
</style>
